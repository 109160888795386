import * as actionTypes from "../actions/actionTypes";
import {updateObject} from '../../utility';

const initialState = {
    error: null,
    loading: false,
    posts: [],
    post: null,
    featuredPost: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_POSTS_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.FETCH_POSTS_SUCCESS:
            return updateObject(state, {posts: action.posts, loading: false, error: null});
        case actionTypes.FETCH_POSTS_FAIL:
            return updateObject(state, {error: action.error, loading: false});
        case actionTypes.SET_FEATURED_POST:
            return updateObject(state, {featuredPost: action.featuredPost});

        case actionTypes.SET_POST_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.SET_POST_SUCCESS:
            return updateObject(state, {post: action.post, loading: false, error: null});
        case actionTypes.SET_POST_FAIL:
            return updateObject(state, {error: action.error, loading: false});
        default:
            return state;
    }
};

export default reducer;