import * as actionTypes from "./actionTypes";

export const fetchPostsStart = () => {
    return {
        type: actionTypes.FETCH_POSTS_START
    };
};
export const fetchPostsSuccess = posts => {
    return {
        type: actionTypes.FETCH_POSTS_SUCCESS,
        posts: posts
    };
};

export const setFeaturedPost = featuredPost => {
    return {
        type: actionTypes.SET_FEATURED_POST,
        featuredPost: featuredPost
    };
};

export const fetchPostsFail = error => {
    return {
        type: actionTypes.FETCH_POSTS_FAIL,
        error: error
    };
};

export const postsInit = axios => {
    return dispatch => {
        dispatch(fetchPostsStart());
        axios
            .get("/posts?filter[posts_per_page]=-1")
            .then(response => {
                // console.log(response);
                let featuredPost = null;
                let posts = response.data.map(t => {
                    if (t.data_obj.isFeaturedPost) {
                        featuredPost = t.data_obj;
                        dispatch(setFeaturedPost(featuredPost));
                    }
                    return t.data_obj;
                });
                dispatch(fetchPostsSuccess(posts));
            })
            .catch(error => {
                dispatch(fetchPostsFail(error.message));
            });
    };
};

export const setPostStart = () => {
    return {
        type: actionTypes.SET_POST_START
    };
};

export const setPostSuccess = post => {
    return {
        type: actionTypes.SET_POST_SUCCESS,
        post: post
    };
};

export const setPostFail = error => {
    return {
        type: actionTypes.SET_POST_FAIL,
        error: error
    };
};

export const loadCurrentPost = (postName, posts, axios, history) => {
    return dispatch => {
        dispatch(setPostStart());
        if (posts.length < 1) {
            axios
                .get("/posts?filter[posts_per_page]=-1")
                .then(response => {
                    let featuredPost = null;
                    let posts = response.data.map(t => {
                        if (t.data_obj.isFeaturedPost) {
                            featuredPost = t.data_obj;
                            dispatch(setFeaturedPost(featuredPost));
                        }
                        return t.data_obj;
                    });

                    let currentPost = posts.find(p => p.slug === postName);
                    if (!currentPost) {
                        dispatch(
                            setPostFail(
                                "No blog post exist with the name " + postName
                            )
                        );
                        history.push("/blog");
                    } else {
                        dispatch(setPostSuccess(currentPost));
                    }

                    dispatch(fetchPostsSuccess(posts));
                })
                .catch(error => {
                    dispatch(fetchPostsFail(error.message));
                });
        } else {
            let currentPost = posts.find(p => p.slug === postName);
            if (!currentPost) {
                dispatch(
                    setPostFail("No blog post exist with the name " + postName)
                );
                history.push("/blog");
            } else {
                dispatch(setPostSuccess(currentPost));
            }
        }
    };
};