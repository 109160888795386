import React, {useState, useEffect} from 'react';
import {Row, Col, Snackbar, Loader, Form, HTMLContent, Social, Container} from 'inspirepress-styleguide';
import GMaps from "../Components/GMaps";
import {useSelector} from 'react-redux'
import axios from '../axios';


const Contact = () => {
    const {contact_number, contact_email, googleMapsApiKey, formSubmittedMessage, ...rest} = useSelector(state => state.settings.cmsContent);
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setSnackbar] = useState(false);
    const [pageObj, setPageObj] = useState({});
    // const [error, setError] = useState(null);

    useEffect(() => {

        axios.get('/pages?slug=contact')
            .then(res => setPageObj(res.data[0].data_obj))
            .catch(err => console.log(err.response.data));
    }, []);

    let form = {name: '', email: '', phone: '', service: '', message: ''}
    let [values, setValues] = useState(form);
    let elements = [
        {slug: 'name', label: 'Name', type: 'text', grid: 'col--md--6', rules: ['required']},
        {slug: 'phone', label: 'Phone', type: 'text', grid: 'col--md--6', rules: ['required']},
        {slug: 'email', label: 'Email', type: 'text', grid: 'col--md--12', rules: ['required', 'email']},
        {
            slug: 'service', label: 'Service', type: 'select', grid: 'col--md--12', rules: ['required'],
            options: [
                {key: 'fullhouse', text: 'Full House Renovation'},
                {key: 'kitchen', text: 'Kitchen Renovation'},
                {key: 'bathroom', text: 'Bathroom Renovation'},
            ]
        },
        {slug: 'message', label: 'Message', type: 'textarea', grid: 'col--md--12', rules: ['required']},
    ];

    const onSubmit = () => {
        setLoading(true);
        // send request to server
        axios.post('/sendemail', values)
            .then(res => {
                setLoading(false);
                setSnackbar(true);
                setValues(form);
            })
            .catch(err => console.log(err.response.data))
    }
    // 51.4305721,-0.3074127
    // 51.4305202, -0.3072176
    // 51.4305458,-0.307227
    return (
        <div className='page-content' id="contact">
            <Row >
                <Col md12>
                    <GMaps
                        lat={51.4305458}
                        lng={-0.307227}
                        height={500}
                        styles={[{"featureType": "administrative.country", "elementType": "geometry", "stylers": [{"visibility": "simplified"}, {"hue": "#ff0000"}]}]}
                        apiKey={googleMapsApiKey}
                    />
                </Col>
            </Row>

            <Container>
                <Snackbar
                    show={showSnackbar}
                    title="form submitted"
                    message={formSubmittedMessage}
                    onComplete={() => setSnackbar(false)}
                />

                <Row gutt3 className="py-6">

                    <Col md6 className="mp-2">
                        <div className="contact__header contact_page">
                            <h1>{pageObj.title || "Contact Us"}</h1>
                            <HTMLContent className="my-4" content={pageObj.content} />
                            {contact_number && <a href={`tel:${contact_number}`}  >{contact_number}</a>}
                            <br />
                            {contact_email && <a href={`mailto:${contact_email}`} > {contact_email}</a>}
                            <Social data={rest} className="mt-5" />
                        </div>

                    </Col>
                    <Col md6 className="mp-2">
                        <Loader show={loading} invert />

                        <Form
                            theme="error"
                            btnSize="lg"
                            values={values}
                            elements={elements} onChange={(f) => setValues(f)}
                            onSubmit={onSubmit}
                        />
                    </Col>
                </Row>
            </Container>




        </div >
    );
};

export default Contact;