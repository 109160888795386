import React, {useEffect} from 'react';
import axios from "../axios";
import {withRouter} from 'react-router-dom';
import {Col, HTMLContent, Img, Row} from 'inspirepress-styleguide';
import {useDispatch, useSelector} from 'react-redux';
import {loadCurrentPost} from "../store/actions/index";

const Post = (props) => {
    const {posts, post} = useSelector(state => state.posts);
    const dispatch = useDispatch();

    useEffect(() => {
        let currentPage = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
        dispatch(loadCurrentPost(currentPage, posts, axios, props.history));
    }, [props.location, props.history, dispatch, posts]);


    return (
        <Row className="page-content py-5 ">
            <Col md8 mdOffset2 className="mp-2">
                {post &&
                    <Row>
                        <Col md12>
                            <div className="page-content__heading">
                                <h1>{post.title}</h1>
                                {post.previewParagraph && <HTMLContent className="page-content__subHeading" content={post.previewParagraph} />}
                            </div>
                            {post.img_url && <Img src={post.img_url} />}
                            <HTMLContent content={post.content} />
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
};

export default withRouter(Post);