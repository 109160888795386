import React from 'react';
import {useSelector} from "react-redux";
import HomeHeader from "../Components/HomeHeader";
import HomeFooter from "../Components/HomeFooter";
import {Row, Col, HTMLContent, Img} from "inspirepress-styleguide";
import MainHeader from '../Components/MainHeader';
import MobileMenu from '../Components/MobileMenu';

const HomeLayout = (props) => {

    const {logoImg, banner_text, banner_bk_img} = useSelector(state => state.settings.cmsContent);

    return (
        <Row className="home">
            <Col md12>
                <MobileMenu />
                <div className="nk-banner" style={{backgroundImage: `url(${banner_bk_img})`}}>
                    <div className="overlay"></div>
                    <HomeHeader />
                    <div className="nk-banner__inner ">
                        <Row className="my-5">
                            <Col md10 mdOffset1>
                                <div className="nk-banner__logo-wrap" >

                                    {logoImg ? <Img src={logoImg} /> : <div className="nk-banner__heading"><div>casa</div><div>reno</div></div>}

                                    {banner_text ? <HTMLContent content={banner_text} /> : null}
                                </div>
                                {/* <div className="onlyBigScreen " > <MainHeader /> </div> */}
                            </Col>
                        </Row>

                    </div>
                    <div className="mainnav-wrap">
                        <Row >
                            <Col md10 mdOffset1>
                                <div className="onlyBigScreen mainnav" > <MainHeader /> </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>

            <Col md12>
                {props.children}
            </Col>

            <Col md12>
                <HomeFooter />
            </Col>
        </Row>
    )
};

export default HomeLayout;