import React from 'react';
import About from '../Components/About';
import Projects from '../Components/Projects';
import RichmondKitchens from '../Components/RichmondKitchens';
import {Carousel} from "inspirepress-styleguide";
import {useSelector} from "react-redux";

const Home = () => {

    const {reviews} = useSelector(state => state.reviews);
    const {testimonials_heading, testimonials_subHeading, yoga_bk_img} = useSelector(state => state.settings.cmsContent);

    return (
        <React.Fragment>
            <About />
            <Projects />
            <RichmondKitchens />
            <Carousel
                list={reviews}
                intervals={7000}
                id="testimonials"
                heading={testimonials_heading || "Testimonials"}
                subHeading={testimonials_subHeading}
                isHtmlContent
                error
            />
            {/* <Contact /> */}

        </React.Fragment>
    );
};

export default Home;