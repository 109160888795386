export const getFilteredSortedPosts = (posts, filterTaxonomies, sortCriteria) => {
    let filteredPosts = getFilteredPosts(posts, filterTaxonomies);
    let filteredSortedPosts = getSortedPosts(filteredPosts, sortCriteria);

    return filteredSortedPosts;
};

export const getFilteredPosts = (posts, filterTaxonomies) => {
    if (filterTaxonomies.length === 0) return posts;

    let filteredPosts = posts.filter(post => {
        let satisfiedfilters = [];

        filterTaxonomies.forEach(tax => {
            let filterTaxonomyTermIds = tax.terms.map(t => t.term_id);
            let postTaxTermIds = post.taxonomyIds[tax.slug];

            filterTaxonomyTermIds.forEach(id => {
                if (postTaxTermIds.includes(id)) {
                    if (!satisfiedfilters.includes(tax.slug)) satisfiedfilters.push(tax.slug);
                }
            });
        });

        return filterTaxonomies.length === satisfiedfilters.length ? true : false;
    });

    return filteredPosts;
};
export const getSortedPosts = (posts, sortCriteria) => {
    return posts;
};

export const taxonomyObjToTermsAr = taxonomies => {
    return Object.keys(taxonomies).reduce((acc, tax) => {
        return acc.concat(taxonomies[tax]);
    }, []);
};

export const getProductPrice = (type, priceHtml) => {
    switch (type) {
        case "variable":
            return priceHtml;

        default:
            return priceHtml;
    }
};