import * as actionTypes from "../actions/actionTypes";
import {updateObject} from '../../utility';

const initialState = {
    error: null,
    loading: false,
    reviews: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REVIEWS_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.FETCH_REVIEWS_SUCCESS:
            return updateObject(state, {reviews: action.reviews, loading: false, error: null});
        case actionTypes.FETCH_REVIEWS_FAIL:
            return updateObject(state, {error: action.error, loading: false});

        default:
            return state;
    }
};

export default reducer;