
export const objToArray = obj => {
    return Object.keys(obj).map(item => obj[item]);
}

export const flattenArray = array => {

}

export const flattenObj = ob => {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object') {
            var flatObject = flattenObj(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                // toReturn[i + '.' + x] = flatObject[x];
                toReturn[x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    // console.log(toReturn);
    return toReturn;
};


export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const isNumberInRange = (x, minMax) => {
    return x >= minMax[0] && x <= minMax[1];
}

export const IsNumberInAnyRanges = (x, rangesAr) => {
    let isInRange = false;
    rangesAr.forEach(range => {
        if (isNumberInRange(x, range))
            isInRange = true;
    });

    return isInRange;
}


export const getZoonSpecificShippingMethods = (countryCode, zoonsObject) => {
    let zoonSpecificMethod = zoonsObject.find(item => item.zoneCodes.includes(countryCode));
    let outsideShippingZone = zoonsObject.find(item => item.zoneCodes.length === 0);
    return zoonSpecificMethod ? zoonSpecificMethod.methods : outsideShippingZone.methods;
}

export const getObjPropertyFromArray = (array, slug, value, property) => {
    let obj = array.find(item => item[slug] === value);
    return obj ? obj[property] : obj;
}


export const importAll = r => {
    let images = {};
    r.keys().map(item => images[item.replace('./', '')] = r(item));
    return images;
}

export const initializeApp = () => {
    // set document title
    // TODO :: change programatically for other pages
    document.title = process.env.REACT_APP_TITLE;
}


