import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {documentTitleFromPath} from '../extras/url';

const DocumentTitle = (props) => {
    let location = useLocation();
    useEffect(() => {
        document.title = documentTitleFromPath(process.env.REACT_APP_TITLE, location.pathname);
    }, [location]);

    return (props.children);
};

export default DocumentTitle;