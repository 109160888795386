import * as actionTypes from "./actionTypes";

export const fetchReviewsStart = () => {
    return {
        type: actionTypes.FETCH_REVIEWS_START
    };
};
export const fetchReviewsSuccess = reviews => {
    return {
        type: actionTypes.FETCH_REVIEWS_SUCCESS,
        reviews: reviews
    };
};


export const fetchReviewsFail = error => {
    return {
        type: actionTypes.FETCH_REVIEWS_FAIL,
        error: error
    };
};

export const fetchReviews = axios => {
    return dispatch => {
        dispatch(fetchReviewsStart());
        axios
            .get("/review?filter[posts_per_page]=4")
            .then(response => {
                let reviews = response.data.map(t => {
                    return {
                        ...t.data_obj,
                        author: t.data_obj.reviewAuthor
                    };
                    // return t.data_obj;
                });
                dispatch(fetchReviewsSuccess(reviews));
            })
            .catch(error => dispatch(fetchReviewsFail(error.response.data)));
    };
};
