import React, {useState, useEffect} from 'react';
import {Row, Col, Container, Form, Snackbar, Loader, HTMLContent} from 'inspirepress-styleguide';
import {useSelector} from 'react-redux';
import axios from '../axios';

const Bookings = (props) => {
    const defaultForm = {name: '', email: '', phone: '', date: '', location: '', message: '', service: ''};
    const {formSubmittedMessage} = useSelector(state => state.settings.cmsContent);
    const [values, setValues] = useState(defaultForm);
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setSnackbar] = useState(false);

    const [pageObj, setPageObj] = useState({});
    // const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('/pages?slug=bookings')
            .then(res => setPageObj(res.data[0].data_obj))
            .catch(err => console.log(err.response.data));
    }, []);

    const date = new Date();
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    let elements = [
        {slug: 'name', label: 'Name', type: 'text', grid: 'col--md--6', rules: ['required']},
        {slug: 'phone', label: 'Phone', type: 'text', grid: 'col--md--6', rules: ['required']},
        {slug: 'email', label: 'Email', type: 'text', grid: 'col--md--12', rules: ['required', 'email']},
        {
            slug: 'location', label: 'Location', type: 'select', grid: 'col--md--12', rules: ['required'],
            options: [
                {key: 'Kingston', text: 'Kingston Showroom'},
                {key: 'richmond', text: 'Richmond Showroom'},
                {key: 'onsite', text: 'On Site'},
            ]
        },
        {slug: 'date', label: 'Date', type: 'dateTime', grid: 'col--md--12', rules: ['required'], options: {minDate: tomorrow, enableTime: true}},
        {slug: 'message', label: 'Message', type: 'textarea', grid: 'col--md--12'},
        {
            slug: 'service', label: 'Service', type: 'radio', grid: 'col--md--12', rules: ['required'],
            options: [
                {key: 'fullhouse', label: 'Full House Renovation'},
                {key: 'kitchen', label: 'Kitchen Renovation'},
                {key: 'bathroom', label: 'Bathroom Renovation'},
            ]
        },
    ];

    const onSubmit = () => {
        setLoading(true);
        // send request to server
        axios.post('/send-booking-email', values)
            .then(res => {
                setLoading(false);
                setSnackbar(true);
                setValues(defaultForm);
            })
            .catch(err => console.log(err.response.data))
    }

    return (
        <Container className="px-3">
            <Snackbar
                show={showSnackbar}
                title="form submitted"
                message={formSubmittedMessage}
                onComplete={() => setSnackbar(false)}
            />

            <Row >
                <Col md6 mdOffset3>
                    <h1 className="ta-center" style={{textTransform: 'capitalize'}}>{pageObj.title || 'Bookings'}</h1>
                    <HTMLContent className="ta-center" content={pageObj.subHeading} />
                    <HTMLContent className="mb-5 ta-center" content={pageObj.content} />

                    <Loader show={loading} invert />

                    <Form
                        theme="error"
                        btnSize="lg" values={values}
                        elements={elements}
                        onChange={(f) => setValues(f)}
                        onSubmit={onSubmit} />

                </Col>
            </Row>
        </Container>
    );
};

export default Bookings;