import React from 'react';

const Container = (Parent, Child) => {
    return (props) => (
        <Parent>
            <Child />
        </Parent>
    );
};

export default Container;