import React from 'react';
import {Row, Container, Col, HeadLine, Img, HTMLContent, Card} from 'inspirepress-styleguide';
// import * as images from '../extras/images';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

const Projects = () => {

    const {projects} = useSelector(state => state.projects);
    const {projects_heading, projects_text} = useSelector(state => state.settings.cmsContent);

    return (
        // <div className="pb-6 pt-2 projects">
        <div className="py-6 projects mp-5">
            <Row className=" mb-5 ta-center ">
                <Col md8 mdOffset2 >
                    <h1>{projects_heading || "Projects"}</h1>
                    {projects_text && <HTMLContent content={projects_text} />}
                </Col>
            </Row>
            <Row>
                <Col md10 mdOffset1 >
                    <Row gutt3 >
                        {projects &&
                            projects.map(p => {
                                return (
                                    <Col md4 key={p.id}>
                                        <Card
                                            renderLink={(link, text) => <Link to={link} >{text}</Link>}
                                            elevated
                                            imgWithOverlay
                                            className='mb-4'
                                            img={p.img_url}
                                            title={p.title}
                                            previewText={p.previewParagraph}
                                            link={`/projects/${p.slug}`}
                                        />



                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>
            </Row>
        </div >
    );
};

export default Projects;