import React from 'react';
import {Row, Col, HeadLine, Img, Btn, HTMLContent} from 'inspirepress-styleguide';
import {useSelector} from 'react-redux'
import Section from './Section';

const RichmondKitchens = (props) => {
    const {rk_heading, rk_text, rk_img, rk_btn_text, rk_btn_link} = useSelector(state => state.settings.cmsContent);


    return (
        <div className='richmondKitchens pt-6' id="richmondKitchens" >
            <Row >
                <Col md10 mdOffset1>
                    <Section
                        img={rk_img || undefined}
                        content={rk_text || undefined}
                        heading={rk_heading || "Richmond Kitchens"}
                        btnText={rk_btn_text || undefined}
                        link={rk_btn_link}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RichmondKitchens;