import React from 'react';
import {Toolbar, Logo} from "inspirepress-styleguide";
import {Link} from "react-router-dom";
import MainHeader from '../Components/MainHeader';
import MobileMenu from '../Components/MobileMenu';
import {useSelector} from "react-redux";

const Header = (props) => {

    const {logoImg} = useSelector(state => state.settings.cmsContent);

    return (
        <Toolbar dark lg>
            <Logo
                src={logoImg || undefined}
                render={(html) => <Link to='/'>{html}</Link>}
            />
            <MainHeader />
            <MobileMenu isIcon />
            {/* <MobileMenu /> */}
        </Toolbar>
    );
};

export default Header;