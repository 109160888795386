import React, {useCallback, useEffect, useRef} from 'react';
import Marker from '../assets/img/mapsMarker.png';

const GMaps = ({lat, lng, apiKey, zoom, styles, height, ...props}) => {
    const mapRef = useRef();
    var fallBackStyles = [{"featureType": "administrative.country", "elementType": "labels", "stylers": [{"visibility": "off"}]}, {"featureType": "landscape", "elementType": "geometry.fill", "stylers": [{"color": "#b5ab94"}]}, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "poi.attraction", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "poi.business", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "poi.government", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "poi.school", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "road", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "road.highway", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "road.local", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "transit.line", "elementType": "all", "stylers": [{"visibility": "on"}]}, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#b0e4f7"}, {"visibility": "on"}]}];

    const createGoogleMap = useCallback((lat, lng) => {
        let map = new window.google.maps.Map(mapRef.current, {
            zoom: zoom || 15,
            center: {lat: lat, lng: lng},
            disableDefaultUI: true,
            styles: styles || fallBackStyles
        });
        new window.google.maps.Marker({
            position: {lat: lat, lng: lng},
            map: map,
            title: "Hello World!",
            icon: new window.google.maps.MarkerImage(Marker)
        });
    }, [styles, zoom, fallBackStyles]);

    const loadMapScript = useCallback((key, lat, lng) => {
        if (!lat || !lng || !key) return;
        let googleScript = window.document.getElementById("react-gmap");
        if (!googleScript) {
            const googleScript = document.createElement("script");
            googleScript.src = `//maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
            googleScript.id = "react-gmap";
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener("load", () => createGoogleMap(lat, lng));
        } else {
            createGoogleMap(lat, lng);
        }
    }, [apiKey, createGoogleMap]);

    useEffect(() => loadMapScript(apiKey, lat, lng), [apiKey, lat, lng]);

    return (
        <div id='google-map' ref={mapRef} style={{width: "100%", height: !height ? "370px" : `${height}px`, }} />
    );
};

export default GMaps;



