import * as utility from "../../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    isDrawerOpen: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_DRAWER:
            return utility.updateObject(state, {isDrawerOpen: true});
        case actionTypes.CLOSE_DRAWER:
            return utility.updateObject(state, {isDrawerOpen: false});
        case actionTypes.TOGGLE_DRAWER:
            return utility.updateObject(state, {isDrawerOpen: !state.isDrawerOpen});
        default:
            return state;
    }
};

export default reducer;