import React from 'react';
import {Social, Container, Row, Col} from "inspirepress-styleguide";
import {useSelector} from "react-redux";

const NotFound = () => {
    const content = useSelector(state => state.settings.cmsContent);

    return (
        <Container>
            <Row className="">

                <Col md12>
                    <div className="contact__header contact_page ta-center">
                        <h1 style={{fontSize: '6rem', marginBottom: '20px'}}>404</h1>
                        <h1>Sorry, Page Not Found</h1>
                        <p>The page you requested could not be found.</p>
                        <Social data={content} className="mt-5 f-jc-center" alignCenter />
                    </div>

                </Col>

            </Row>
        </Container>
    );
};

export default NotFound;