import React, {useEffect, useState} from 'react';
import axios from "../axios";
import {withRouter} from 'react-router-dom';
import {Col, HTMLContent, Row, Img} from 'inspirepress-styleguide';


const Page = (props) => {
    const [pageObj, setPageObj] = useState({});
    // const [error, setError] = useState(null);

    useEffect(() => {
        let currentPage = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
        axios.get('/pages?slug=' + currentPage)
            .then(res => setPageObj(res.data[0].data_obj))
            .catch(err => console.log(err.response.data));
    }, [props.location]);


    return (
        <Row className="page-content py-5">
            <Col md8 mdOffset2 className="mp-2">
                {pageObj &&
                    <Row>
                        <Col md12>
                            <div className="page-content__heading">
                                <h1>{pageObj.title}</h1>
                                {pageObj.subHeading && <HTMLContent className="page-content__subHeading" content={pageObj.subHeading} />}
                            </div>
                            {pageObj.img_url && <Img src={pageObj.img_url} />}

                            <HTMLContent content={pageObj.content} />

                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
};

export default withRouter(Page);