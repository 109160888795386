import React from 'react';
import {Drawer, Logo, NavigationItems, Social} from "inspirepress-styleguide";
import {NavLink, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {openDrawer, closeDrawer} from '../store/actions/index';

const MobileMenu = ({isIcon, ...props}) => {
    const dispatch = useDispatch();
    const {contact_number, contact_email, logoImg, ...rest} = useSelector(state => state.settings.cmsContent);
    const {mobile} = useSelector(state => state.settings.menus);
    const {isDrawerOpen} = useSelector(state => state.general);

    const drawerHtml = (
        < Drawer isOpen={isDrawerOpen} right onClose={() => dispatch(closeDrawer())} >
            <Logo src={logoImg || undefined} />
            <div className="mt-4"></div>
            <NavigationItems items={mobile} render={(t, h) => <NavLink exact to={t}>{h}</NavLink>} />

            {contact_number && <a href={`tel:${contact_number}`}  >{contact_number}</a>} <br />
            {contact_email && <a href={`mailto:${contact_email}`} > {contact_email}</a>}
            <Social data={rest} className="mt-5" alignCenter />
        </ Drawer>
    );

    const iconHtml = (
        <p
            style={{cursor: 'pointer', padding: '8px 0px 8px 8px'}}
            onClick={() => dispatch(openDrawer())}
            className="onlyMobil"
        >
            ☰
        </p >
    );

    return (
        isIcon ? iconHtml : drawerHtml
    );
};

export default withRouter(MobileMenu);
