// settings
export const FETCH_SETTINGS_START = "FETCH_SETTINGS_START";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAIL = "FETCH_SETTINGS_FAIL";


// APP GENERAL
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";

// posts
export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAIL = "FETCH_POSTS_FAIL";
export const SET_FEATURED_POST = "SET_FEATURED_POST";

export const SET_POST_START = "SET_POST_START";
export const SET_POST_SUCCESS = "SET_POST_SUCCESS";
export const SET_POST_FAIL = "FETCH_POST_FAIL";

// reviews
export const FETCH_REVIEWS_START = "FETCH_REVIEWS_START";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAIL = "FETCH_REVIEWS_FAIL";

// projects
export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAIL = "FETCH_PROJECTS_FAIL";

export const SET_PROJECT_START = "SET_PROJECT_START";
export const SET_PROJECT_SUCCESS = "SET_PROJECT_SUCCESS";
export const SET_PROJECT_FAIL = "SET_PROJECT_FAIL";



// // taxonomies
// export const FETCH_TAXONOMIES_START = "FETCH_TAXONOMIES_START";
// export const FETCH_TAXONOMIES_SUCCESS = "FETCH_TAXONOMIES_SUCCESS";
// export const FETCH_TAXONOMIES_FAIL = "FETCH_TAXONOMIES_FAIL";

// // events
// export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
// export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
// export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";

// export const SET_PRODUCT_START = "SET_PRODUCT_START";
// export const SET_PRODUCT_SUCCESS = "SET_PRODUCT_SUCCESS";
// export const SET_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

// //
// export const INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM";
// export const DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM";
// export const EMPTY_CART = "EMPTY_CART";
// export const SET_CART_LOADING = "SET_CART_LOADING";