import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../utility';

const initialState = {
    error: null,
    routes: [],
    menus: [],
    cmsContent: null,
    taxonomies: null,
    loading: true
};

const fetchSettingsStart = (state, action) => {
    return updateObject(state, {loading: true});
}
const fetchSettingsSuccess = (state, action) => {
    return updateObject(state,
        {
            routes: action.settings.routes,
            menus: action.settings.menus,
            cmsContent: action.settings.content_settings,
            taxonomies: action.settings.taxonomies.postTypes,
            loading: false,
            error: null
        });
}
const fetchSettingsFail = (state, action) => {
    return updateObject(state, {error: action.error, loading: false});
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_SETTINGS_START: return fetchSettingsStart(state, action);
        case actionTypes.FETCH_SETTINGS_SUCCESS: return fetchSettingsSuccess(state, action);
        case actionTypes.FETCH_SETTINGS_FAIL: return fetchSettingsFail(state, action);

        default: return state;
    }
};

export default reducer;