import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from "react-redux";
import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import general from "./store/reducers/general";
import settings from "./store/reducers/settings";
import posts from "./store/reducers/posts";
import reviews from "./store/reducers/reviews";
import projects from "./store/reducers/projects";
import ScrollToTop from './Components/ScrollToTop';
import DocumentTitle from './Components/DocumentTitle';

const rootReducer = combineReducers({
    general,
    settings,
    posts,
    reviews,
    projects,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <DocumentTitle>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </DocumentTitle>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root')
);

