import * as actionTypes from './actionTypes';

export const fetchSettingsStart = () => {
    return {
        type: actionTypes.FETCH_SETTINGS_START
    }
}

export const fetchSettingsSuccess = (settings) => {
    return {
        type: actionTypes.FETCH_SETTINGS_SUCCESS,
        settings: settings
    }
}

export const fetchSettingsFail = (error) => {
    return {
        type: actionTypes.FETCH_SETTINGS_FAIL,
        error: error
    }
}

export const settingsInit = (axios) => {
    return dispatch => {
        dispatch(fetchSettingsStart());
        axios.get('appsettings')
            .then(response => dispatch(fetchSettingsSuccess(response.data)))
            .catch(error => dispatch(fetchSettingsFail(error.message)));
    }
}
