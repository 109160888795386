import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {settingsInit, postsInit} from './store/actions/index';
import axios from './axios';
import {SiteLoader} from "inspirepress-styleguide";
import "inspirepress-styleguide/dist/index.css";
import {Switch, Route, Redirect} from "react-router-dom";
import container from './hoc/Container';
import MainLayout from './hoc/MainLayout';
import HomeLayout from './hoc/HomeLayout';
import Home from './Pages/Home';
import Blog from './Pages/Blog';
import Projects from './Pages/Projects';
import Project from './Pages/Project';
import Bookings from './Pages/Bookings';
import Page from './Pages/Page';
import Post from './Pages/Post';
import Contact from './Pages/Contact';
import NotFound from './Pages/NotFound';
import {fetchReviews, fetchProjects} from './store/actions/index';

function App() {
    const {loading, error, routes} = useSelector(store => store.settings);
    const components = {Home, MainLayout, HomeLayout, Page, Blog, Post, Bookings, Contact, Projects, Project};

    const dispatch = useDispatch();
    useEffect(() => {
        if (navigator.userAgent.match(/(iPad|iPhone|iPod|Android|Silk)/gi)) {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add('--ios');
        }
        dispatch(settingsInit(axios));
        dispatch(postsInit(axios));
        dispatch(fetchReviews(axios));
        dispatch(fetchProjects(axios));
    }, [dispatch]);

    return (
        <div className="App">
            <SiteLoader show={loading} progress />
            {error ? <p>error ...</p> : null}
            {!routes || loading ? null :
                <Switch>
                    {routes.map(r => {
                        return (
                            <Route
                                key={r.name}
                                exact
                                path={r.path}
                                component={container(components[r.params.layout], components[r.component])}
                            />
                        )
                    })}
                    {/* <Route path='/bookings' component={container(MainLayout, Bookings)} /> */}
                    <Route path='/notfound' component={container(MainLayout, NotFound)} />
                    <Redirect from='*' to='/notfound' />
                </Switch>
            }
        </div>
    );
}

export default App;