import React, {Component} from "react";
import {connect} from "react-redux";
import {PaginatedContent, Row, Col, Container} from 'inspirepress-styleguide';
import * as WP from "../extras/wp";
import {Link} from "react-router-dom";

class Projects extends Component {
    state = {
        isLoading: false,
        filteredSortedPosts: [],
        filterCriteria: [],
        sortCriteria: []
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.posts !== this.props.posts) {
            this.setFilteredSortedPosts(nextProps.posts);
        }
    }
    componentDidMount() {
        this.setFilteredSortedPosts(this.props.posts);
    }

    setFilteredSortedPosts = blogPosts => {
        const [...posts] = blogPosts;
        const {sortCriteria, filterCriteria} = this.state;

        this.setState({isLoading: true});
        let filteredSortedPosts = WP.getFilteredSortedPosts(posts, filterCriteria, sortCriteria);
        this.setState({filteredSortedPosts, isLoading: false});
    };

    setSelectedTerms = selectedFilters => {
        let posts = [...this.props.posts];
        const sortCriteria = [...this.state.sortCriteria];

        this.setState({isLoading: true});
        let filteredSortedPosts = WP.getFilteredSortedPosts(posts, selectedFilters, sortCriteria);
        this.setState({filterCriteria: selectedFilters, filteredSortedPosts, isLoading: false});
    };

    render() {
        let filteredSortedPosts = [...this.state.filteredSortedPosts];

        return (

            <div>
                {/* <Banner isLoading={this.props.postsLoading} obj={this.props.featuredPost} /> */}
                {/* <Filters
                    taxonomies={this.props.taxonomies}
                    setSelectedTerms={selectedFilters => this.setSelectedTerms(selectedFilters)}
                /> */}
                <Container >
                    <Row>
                        <Col md12>
                            <h1 className="pl-3 mb-0">Projects</h1>
                        </Col>
                    </Row>
                </Container>
                <div className="mp-2">
                    <PaginatedContent
                        data={filteredSortedPosts}
                        pageLimit={12}
                        linkPrefix='/projects/'
                        renderLink={(link, text) => <Link to={link} >{text}</Link>}
                    />

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // postsLoading: state.posts.loading,
        posts: state.projects.projects,
    };
};
export default connect(mapStateToProps)(Projects);