// import React, {useEffect} from 'react';
// import {withRouter} from 'react-router-dom';
// import animateScrollTo from 'animated-scroll-to';

// const ScrollToTop = (props) => {

//     useEffect(() => {
//         if (props.location.pathname !== prevProps.location.pathname) {
//             if (props.location.hash) {
//                 animateScrollTo(document.querySelector(props.location.hash));
//             } else {
//                 window.scrollTo(0, 0);
//             }

//         } else if (props.location.hash !== prevProps.location.hash) {
//             animateScrollTo(document.querySelector(props.location.hash));
//         } else if (props.location.hash) {
//             animateScrollTo(document.querySelector(props.location.hash));
//         }
//     }, [props.location]);

//     return (
//         props.children
//     );
// };

// export default withRouter(ScrollToTop);






import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import animateScrollTo from 'animated-scroll-to';
import {closeDrawer} from '../store/actions/index';
import {connect} from 'react-redux';

class ScrollToTop extends Component {
    componentDidMount() {
        if (this.props.location.hash) {
            // animateScrollTo(document.querySelector(this.props.location.hash));
            // document.addEventListener('DOMContentLoaded', () => animateScrollTo(document.querySelector(this.props.location.hash)))
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {
            this.props.closeDrawer();
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.hash) {
                animateScrollTo(document.querySelector(this.props.location.hash));
            } else {
                setTimeout(() => window.scrollTo(0, 0), 100);

            }

        } else if (this.props.location.hash && this.props.location.hash !== prevProps.location.hash) {
            animateScrollTo(document.querySelector(this.props.location.hash));
        } else if (this.props.location.hash) {
            animateScrollTo(document.querySelector(this.props.location.hash));
        }
    }

    render() {
        return this.props.children;
    }
}


const mapDispatchToProps = dispatch => {
    return {
        closeDrawer: () => dispatch(closeDrawer())
    }
}


export default connect(null, mapDispatchToProps)(withRouter(ScrollToTop));