import React from 'react';
import {useSelector} from 'react-redux';
import {HTMLContent, NavigationItems, Toolbar} from 'inspirepress-styleguide';
import {Link} from "react-router-dom";

const Footer = () => {
    const {footer_paragraph} = useSelector(state => state.settings.cmsContent);
    const {footer: footer_menu} = useSelector(state => state.settings.menus);
    return (
        <div className="footer py-3">
            <Toolbar dark>
                {footer_paragraph && <HTMLContent className="footer__paragraph" content={footer_paragraph} />}
                {footer_menu && <NavigationItems items={footer_menu} render={(to, html) => <Link to={to}>{html}</Link>} />}

            </Toolbar>
        </div>
    );
};

export default Footer;