import * as actionTypes from "./actionTypes";

export const fetchProjectsStart = () => {
    return {
        type: actionTypes.FETCH_PROJECTS_START
    };
};
export const fetchProjectsSuccess = projects => {
    return {
        type: actionTypes.FETCH_PROJECTS_SUCCESS,
        projects: projects
    };
};


export const fetchProjectsFail = error => {
    return {
        type: actionTypes.FETCH_PROJECTS_FAIL,
        error: error
    };
};

export const fetchProjects = axios => {
    return dispatch => {
        dispatch(fetchProjectsStart());
        axios
            .get("/project?filter[posts_per_page]=-1")
            .then(response => {
                let projects = response.data.map(p => p.data_obj);
                dispatch(fetchProjectsSuccess(projects))
            })
            .catch(error => dispatch(fetchProjectsFail(error.response.data)));
    };
};


export const setPrpjectStart = () => {
    return {
        type: actionTypes.SET_PROJECT_START
    };
};

export const setPrpjectSuccess = project => {
    return {
        type: actionTypes.SET_PROJECT_SUCCESS,
        project: project
    };
};

export const setPrpjectFail = error => {
    return {
        type: actionTypes.SET_PROJECT_FAIL,
        error: error
    };
};

export const loadCurrentProject = (postName, posts, axios, history) => {
    return dispatch => {
        dispatch(setPrpjectStart());
        if (posts.length < 1) {
            axios
                .get("/project?filter[posts_per_page]=-1")
                .then(response => {
                    let posts = response.data.map(t => t.data_obj);
                    let currentPost = posts.find(p => p.slug === postName);
                    if (!currentPost) {
                        dispatch(setPrpjectFail("No project exists with the name " + postName));
                        history.push("/projects");
                    } else {
                        dispatch(setPrpjectSuccess(currentPost));
                    }

                    dispatch(fetchProjectsSuccess(posts));
                })
                .catch(error => {
                    dispatch(fetchProjectsFail(error.message));
                });
        } else {
            let currentPost = posts.find(p => p.slug === postName);
            if (!currentPost) {
                dispatch(
                    setPrpjectFail("No project exists with the name " + postName)
                );
                history.push("/projects");
            } else {
                dispatch(setPrpjectSuccess(currentPost));
            }
        }
    };
};