import {capitalizeFirstLetter} from "./string";

export const slugToString = (slug) => {
    return slug
        .split('-')
        .map(capitalizeFirstLetter)
        .join(' ');
}

export const documentTitleFromPath = (siteTitle, path) => {
    let pathAr = path.split('/').reduce((acc, c) => {
        if (c !== '') acc.push(c);
        return acc;
    }, []);

    switch (pathAr.length) {
        case 0: return siteTitle;
        default: return `${slugToString(pathAr[pathAr.length - 1])} | ${capitalizeFirstLetter(siteTitle)}`;
    }
}