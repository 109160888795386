import React from 'react';
import {Row, Col, HeadLine, HTMLContent} from 'inspirepress-styleguide';
import {useSelector} from 'react-redux'

const About = () => {
    const {about_heading, about_text, about_img} = useSelector(state => state.settings.cmsContent);
    return (
        <div id="about">
            <Row >
                <Col md10 mdOffset1>
                    <Row gutt3 >
                        <Col md5 className='f-jc-center df mp-5'>
                            <div className="img-bk-niko">
                                <div className="img-niko" style={{backgroundImage: `url(${about_img})`}}></div>
                            </div>
                        </Col>
                        <Col md7 className="f-aa-center df mp-5">
                            <div>
                                <HeadLine title={about_heading || 'About'} className="mb-2" />
                                {about_text ? <HTMLContent content={about_text} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default About;