import React, {useEffect} from 'react';
import axios from "../axios";
import {withRouter} from 'react-router-dom';
import {Col, HTMLContent, ImageGallery, Img, Row} from 'inspirepress-styleguide';
import {useDispatch, useSelector} from 'react-redux';
import {loadCurrentProject} from "../store/actions/index";

const Project = (props) => {
    const {projects, project} = useSelector(state => state.projects);
    const dispatch = useDispatch();

    useEffect(() => {
        let currentPage = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
        dispatch(loadCurrentProject(currentPage, projects, axios, props.history));
    }, [props.location, props.history, dispatch, projects]);


    return (
        <Row className="page-content py-5 ">
            <Col md8 mdOffset2 className="mp-2">
                {project &&
                    <Row>
                        <Col md12>
                            <div className="page-content__heading">
                                <h1>{project.title}</h1>
                                {<HTMLContent className="page-content__subHeading" content={project.previewParagraph} />}
                            </div>
                            {project.img_url && <Img src={project.img_url} />}
                            {project.gallery && project.gallery.map(i => <Img src={i.url} />)}

                            <HTMLContent content={project.content} />
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
};

export default withRouter(Project);