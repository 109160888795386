import React from 'react';
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import animateScrollTo from 'animated-scroll-to';
import {useSelector} from 'react-redux';
import {HTMLContent, NavigationItems, Toolbar} from 'inspirepress-styleguide';
import {Link} from "react-router-dom";

const HomeFooter = () => {
    const {footer_paragraph, footer_bk_img} = useSelector(state => state.settings.cmsContent);
    const {footer: footer_menu} = useSelector(state => state.settings.menus);
    const scrollToTop = () => animateScrollTo(0);
    return (
        <div className="home-footer py-4"
            style={{backgroundImage: footer_bk_img && `url(${footer_bk_img})`}}
        >
            <Toolbar dark>
                <div className="btt" onClick={scrollToTop}>
                    <div className="svg-wrap"> <FontAwesomeIcon icon={faChevronUp} color='white' size="lg" /> </div>
                    {/* <h1>Back to top</h1> */}
                </div>
                {footer_paragraph && <HTMLContent className="footer__paragraph" content={footer_paragraph} />}
                {footer_menu && <NavigationItems items={footer_menu} render={(to, html) => <Link to={to}>{html}</Link>} />}

            </Toolbar>
        </div>
    );
};

export default HomeFooter;