import React from 'react';
import {Toolbar, Social, Logo, Row, Col} from "inspirepress-styleguide";
import {useSelector} from 'react-redux';
import MobileMenu from '../Components/MobileMenu';

const HomeHeader = () => {
    const {contact_number, contact_email, ...rest} = useSelector(state => state.settings.cmsContent);
    return (
        <React.Fragment>
            {/* <Toolbar sm className="onlyBigScreen" > */}
            <Row className="onlyBigScreen ewrg">
                <Col md10 mdOffset1 >
                    <Social data={rest} color="white" />
                    <ul>
                        <li><p>{contact_number}</p></li>
                        <li><p>{contact_email}</p></li>
                    </ul>

                </Col>
            </Row>

            {/* </Toolbar> */}

            <Toolbar dark lg className="onlyMobil">
                <Logo />
                <MobileMenu isIcon />
            </Toolbar>
        </React.Fragment>
    );
};

export default HomeHeader;




