import * as actionTypes from "../actions/actionTypes";
import {updateObject} from '../../utility';

const initialState = {
    error: null,
    loading: false,
    projects: [],
    project: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROJECTS_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.FETCH_PROJECTS_SUCCESS:
            return updateObject(state, {projects: action.projects, loading: false, error: null});
        case actionTypes.FETCH_PROJECTS_FAIL:
            return updateObject(state, {error: action.error, loading: false});


        case actionTypes.SET_PROJECT_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.SET_PROJECT_SUCCESS:
            return updateObject(state, {project: action.project, loading: false, error: null});
        case actionTypes.SET_PROJECT_FAIL:
            return updateObject(state, {error: action.error, loading: false});

        default:
            return state;
    }
};

export default reducer;