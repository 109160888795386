import * as actionTypes from "./actionTypes";

export const openDrawer = () => {
    return {
        type: actionTypes.OPEN_DRAWER
    };
};

export const closeDrawer = () => {
    return {
        type: actionTypes.CLOSE_DRAWER
    };
};

export const toggleDrawer = () => {
    return {
        type: actionTypes.TOGGLE_DRAWER
    };
};