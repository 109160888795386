import React from 'react';
import {NavigationItems} from "inspirepress-styleguide";
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

const MainHeader = () => {
    const {primary} = useSelector(state => state.settings.menus);
    return (
        <NavigationItems items={primary} render={(t, h) => <NavLink exact to={t}>{h}</NavLink>} />
    );
};

export default MainHeader;