import React from 'react';
import {Row, Col, HeadLine, Img, Btn, HTMLContent} from 'inspirepress-styleguide';


const Section = ({img, heading, content, btnText, link, ...props}) => {

    return (
        <div className="ab-section">
            <div className="ab-section__img">
                <Img src={img} />
            </div>
            <div className="ab-section__content">
                {heading && <h2>{heading}</h2>}
                <HTMLContent content={content} />
                <Btn
                    error
                    className="mt-4"
                    text={btnText}
                    to={link || undefined}
                />
            </div>
        </div>
    );
};

export default Section;